

<div class="toolbar-wrapper" [ngClass]="{ sticky: stickyNav, navHidden: navHidden && !mobileMenuIsOpened }"
  [class.sticky]="mobileMenuIsOpened">
  <p-toolbar>
    <div class="p-toolbar-container">
      <div class="p-toolbar-group-start">
        <button (click)="toggleSidebarEvent()" pButton pRipple class="c-button icon-only mr-16-px">
          <img src="./../../../../assets/images/navigation/toggle.svg" alt="toggle-sidebar">
        </button>
      </div>
      <div class="p-toolbar-group-center">
      
      <app-search-input  [control]="searchControl" [globalSearch]="true" [name]="'global-patients-search'" [placeholder]="'Search patients name'" [desktopOnly]="true"></app-search-input>
      
      </div>
      <div class="p-toolbar-group-end">

        <ng-container>
          <div class="nav-icons">
            
            @if(userProfile$ | async; as userProfile){
              <button class="no-styles-btn" (click)="menu.toggle($event)" pButton pRipple>
                <app-user-image-block [maxNameWidth]="'160px'"
                [firstName]="userProfile.firstName" 
                [lastName]="userProfile.lastName"
                [role]="userProfile.role"
                [photoUrl]="userProfile.photoUrl"
               [showActions]="false" [showUsername]="true" [showRole]="true"></app-user-image-block>

                <div class="expand-icon-wrapper ml-16-px">
                  <img src="./../../../../assets/images/icons/More.svg" alt="">
                </div>
                <p-menu [styleClass]="'user-p-menu'" #menu [model]="userMenu" [appendTo]="'body'" [popup]="true"></p-menu>
              </button>

            } @else {
              <div class="user-dropdown-loader">
                <p-skeleton shape="circle" height="40px" width="40px" styleClass="mr-2-"></p-skeleton>
                <div class="content">
                  <div class="inner-flex-container">
                    <p-skeleton width="160px" height="16px" [styleClass]="'mb-4-px'" ></p-skeleton>
                    <p-skeleton width="40px" height="14px" ></p-skeleton>
                  </div>
                  <p-skeleton shape="circle" width="20px" height="20px"></p-skeleton>
                </div>
              </div>
      

            }
             
         
      
             
            <!--
              <a  >
                <button  class="sign-up-btn-mobile" pButton pRipple>
                  <img class="account-icon" src="./../../../../assets/images/icons/navigation/user-mask.png"
                    alt="car">
                  <span class="inner-label name-tag">Richardtmur</span>
                </button>
              </a>
            -->
            


          </div>
        </ng-container>

      </div>
    </div>

  </p-toolbar>

  <div class="mobile-toolbar">
    <div class="container">
      <app-search-input [control]="searchControl" [globalSearch]="true" [name]="'global-search2'" [placeholder]="'Search'" [mobileOnly]="true"></app-search-input>
    </div>
  </div>
</div>


<p-sidebar styleClass="user-mobile-sidebar-menu" [(visible)]="userMobileMenuVisible" position="right">
  <ng-template pTemplate="header">Account</ng-template>
  <ng-template pTemplate="content">
    <app-user-inner-menu (closeUserSidenavEvent)="userMobileMenuVisible = false" [inMobileSidenav]="true"></app-user-inner-menu>
  </ng-template>
</p-sidebar>


 